import React from "react";
import { Link, graphql } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import Img from "gatsby-image";
import smartsegment from "../../images/smart-segment-optimized.jpg";
import trendoptimized from "../../images/trent-forecasting.png";
import sentimentanalysis from "../../images/img-sentiment-analysis.png";
import personalizedrec from "../../images/img-personalised-recommendations.png";
import churnoptimized from "../../images/churn-optimized.jpg";
import visualinsights from "../../images/visual-insights.jpg";
// import comment from '../../images/comment.png';
import ContactUs from "../../components/ContactUs";

const Datascience = (props) => {
  return (
    <Layout bodyClass="page-services">
      <Seo
        title="Data Science"
        description="iTelaSoft's data solutions and services help businesses to
                unlock the full value of data through adoption of data science
                and machine learning."
      />
      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img">
          <Img
            fluid={props.data.bannerImg.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Banner Image"
            className="hero-banner"
          />
        </div>
        <div className="container">
          <div className="row hero-section">
            <div className="col-12 wrap-page-title">
              <h1 className="page-title">
                Data Science &amp; Machine Learning
              </h1>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-6 section-2">
        <div className="row">
          <div className="col-12 mt-4 text-center">
            <div className="sub-title-one">Revealing Insights</div>
            <hr />
            <div className="discription">
              <p>
                iTelaSoft's data solutions and services help businesses to
                unlock the full value of data through adoption of data science
                and machine learning. Our data solutions and services offer
                Customer Segmentation, Trend Prediction, Sentiment Analysis,
                Personalised Product Recommendations, Visual Insights and
                Conversational Interfaces.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="Smart Segment" className="img-inno" src={smartsegment} />
          </div>
          <div className="col-12 col-md-6">
            <div className="space">
              <h4>Smart Segmentation</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  Targeting customers with relevant content provides a major
                  opportunity to build a strong, beneficial relationship with
                  them. Additionally, identifying customers with similar
                  characteristics allows the business to optimise their
                  offerings to yield a better return of investment. Our Smart
                  Segmentation solution is able to consider various customer
                  attributes (e.g. demographics, behaviours, etc.) to identify
                  super-targeted segments.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 p-0 order-md-first order-last">
            <div className="space">
              <h4>Trend Forecasting</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  Accurate prediction of future events allows for the evaluation
                  of opportunities and threats in a rapidly evolving business
                  environment. Our forecasting solutions incorporate
                  state-of-the-art machine learning algorithms to accurately
                  predict key business indicators such as sales demand, profit,
                  customer churn, and so on, by utilising historical data.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="Trend Optimized" className="img-inno" src={trendoptimized} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="sentiment analysis" className="img-inno" src={sentimentanalysis} />
          </div>
          <div className="col-12 col-md-6 p-0">
            <div className="space">
              <h4>Sentiment Analysis</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  Businesses can know exactly what makes the customer feel like
                  they are receiving a superior service by analysing the
                  customer sentiments. Our sentiment analysis solutions make use
                  of the latest Natural Language Processing techniques and
                  Machine Learning to understand the customer sentiments on
                  different aspects of products and services.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 p-lg-0 order-md-first order-last">
            <div className="space">
              <h4>Personalised Recommendations</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  Businesses can increase customer satisfaction, retention and
                  revenue by providing personalised product and service
                  offerings. Our personalised recommendation solutions make use
                  of customer details as well as their interactions with
                  different business channels to calibrate the offerings to
                  maximise the key performance indicators (e.g. interaction,
                  revenue, etc.) defined by the business.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="personalized rec"  className="img-inno" src={personalizedrec} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="churn optimized" className="img-inno" src={churnoptimized} />
          </div>
          <div className="col-12 col-md-6">
            <div className="space">
              <h4>Churn & Retention</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  Customers are the backbone of any business, and it is no
                  secret that customer retention is a top priority. In addition,
                  understanding the reasons customers churn and estimating the
                  risk associated with individual customers are both powerful
                  components of designing a data-driven retention strategy. Our
                  customer churn prediction solutions could generate powerful
                  predictive models to timely identify potential churn customers
                  so businesses could effectively engage with them to improve
                  their relationship.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 p-lg-0 order-md-first order-last">
            <div className="space">
              <h4>Visual Insights</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  “A picture is worth a thousand words”. Image analytics is a
                  powerful technique businesses can utilise to understand what
                  is happening on social media. Our visual insight solutions can
                  be used to learn more about your target customers as well as
                  any social trends that are impacting your products and
                  services.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="visual insights" className="img-inno" src={visualinsights} />
          </div>
        </div>
        <div className="row wrap-contact-us">
          <ContactUs />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "datascience-OG-1024x535.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default Datascience;
